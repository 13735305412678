<template>
  <LayoutWithSidebar
    title="カスタムターゲットの新規作成"
    :breadcrumbs="{
      parents: [
        { name: COMPANY_ROUTES.top, label: 'ホーム' },
        { name: CUSTOM_ROUTES.index, label: 'カスタムターゲット' }
      ],
      current: { label: 'カスタムターゲットの新規作成' }
    }"
  >
    <Box
      v-if="isLoadingTargetCategories || basicTargetStore.isLoading"
      position="absolute"
      top="calc(50px + 92px)"
      left="230px"
      margin-top="calc((100vh - 50px - 92px - 38px) / 2 - 18px)"
      margin-left="calc((100% - 230px) / 2 - 18px)"
    >
      <Loading size="m" />
    </Box>
    <CustomTargetForm v-else />
  </LayoutWithSidebar>
</template>
<script setup>
import { CUSTOM_ROUTES, COMPANY_ROUTES } from '@/router';
import { useCustomTargetStore } from '@/store/customTarget/';
import { useBasicTargetsStore } from '@/store/basicTargets';
import { useCategories } from '@/composables/customTarget';
import CustomTargetForm from '@/pages/custom/CustomTargetForm.vue';
import Box from '@/components/layout/Box.vue';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Loading from '@/components/ui/Loading.vue';

const store = useCustomTargetStore();
const basicTargetStore = useBasicTargetsStore();
const { getTargetCategories, isLoadingTargetCategories } = useCategories();
getTargetCategories().then(() => store.initConditionsEnqueteAndPurchase());
</script>
